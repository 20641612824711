<script>
// import vue2Dropzone from "vue2-dropzone";
import { required, minLength } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

// import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";

/**
 * Add-product component
 */
export default {
  page: {
    title: "เพิ่มข้อมูลรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // vueDropzone: vue2Dropzone,
    Multiselect,
    Layout,
    PageHeader,
    DatePicker,
    // DatePicker,
  },
  data() {
    return {
      userBranch: "",
      validateCustomer: {
        nameCusnew: "",
        citizenId: "",
        familyNameTh: "",
        cusType: "",
        mobilePhone: "",
      },
      form: {
        vin: "",
        ownerId: "",
        driverId: "",
        platePrefixNumber: "",
        plateProvinceId: "",
        plateNumber: "",
        branchId: "",
      },

      vin: "",
      chassisNumber: "",
      engineNumber: "",
      platePrefixNumber: "",
      plateNumber: "",
      licensePlate: "",
      gearSystemId: "",
      modelYear: "",
      seat: "",
      warrantyBookNo: "",
      keyNo: "",
      cc: "",
      deliveryDate: "",
      registrationDate: "",
      coverageEndDate: "",
      coverageEndMileNumber: "",
      lastServiceDate: "",
      previousServiceDate: "",
      lastInvoiceNumber: "",
      previousInvoiceNumber: "",
      previousMileNumber: "",
      mileNumber: "",
      averageMileNumber: "",
      predictMileNumber: "",
      branchId: "",
      plateProvinceId: "",
      ownerId: "",
      driverId: "",
      typeId: "",
      brandId: "",
      modelId: "",
      interiorColorId: "",
      exteriorColorId: "",
      userBranchId: "",

      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      masterBrand: [],
      masterModel: [],
      masterType: [],
      masterGear: [],
      provinces: [],
      rowsUser: [],
      branchMaster: [],
      optioncusType: [
        {
          type: "P",
          nameTh: "บุคคล",
        },
        {
          type: "B",
          nameTh: "นิติ",
        },
      ],
      title: "เพิ่มข้อมูลรถ",
      submitform: false,
      submitformcustomer: false,
      submit: false,
      typesubmit: false,
      value: null,
      rows: [],
      dataTest: [],
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: "ข้อมูลรถ",
          active: false,
          href: "/vehiclelist",
        },
        {
          text: "เพิ่มข้อมูลรถ",
          active: true,
        },
      ],
    };
  },
  validations: {
    form: {
      vin: {
        required,
      },
      ownerId: {
        required,
      },
      driverId: {
        required,
      },
      platePrefixNumber: {
        required,
      },
      plateProvinceId: {
        required,
      },
      plateNumber: {
        required,
      },
      branchId: {
        required,
      },
    },
    validateCustomer: {
      nameCusnew: {
        required,
      },
      familyNameTh: {
        required,
      },
      cusType: {
        required,
      },
      mobilePhone: {
        required,
        minLength: minLength(9),
      },
    },
  },

  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
    this.userBranchId = user.branchId;
    this.branchMaster = user.userAccessBranch;
  },
  computed: {
    fullLicense: function() {
      return `${this.form.platePrefixNumber} ${this.form.plateNumber} ${
        this.form.plateProvinceId != null && this.form.plateProvinceId.nameTh
          ? this.form.plateProvinceId.nameTh
          : ""
      }`;
    },
  },
  created() {
    // this.getSelecttest();
    this.getSelectMasterBrand();
    // this.getSelectMasterModel();
    this.getSelectMasterType();
    this.getSelectMasterGear();
    this.getSelectMasterProvinces();
    // this.getDetailVehicle();
  },
  methods: {
    submitVehicles: function() {
      const bodyFormData = new FormData();
      bodyFormData.append("vin", this.form.vin);
      bodyFormData.append("chassisNumber", this.chassisNumber);
      bodyFormData.append("engineNumber", this.engineNumber);
      bodyFormData.append("platePrefixNumber", this.form.platePrefixNumber);
      bodyFormData.append("plateNumber", this.form.plateNumber);
      bodyFormData.append("licensePlate", this.fullLicense);
      bodyFormData.append(
        "gearSystemId",
        this.gearSystemId.gearSystemId != null
          ? this.gearSystemId.gearSystemId
          : ""
      );
      bodyFormData.append("modelYear", this.modelYear);
      bodyFormData.append("seat", this.seat);
      bodyFormData.append("warrantyBookNo", this.warrantyBookNo);
      bodyFormData.append("keyNo", this.keyNo);
      bodyFormData.append("cc", this.cc);
      bodyFormData.append("deliveryDate", this.deliveryDate);
      bodyFormData.append("registrationDate", this.registrationDate);
      bodyFormData.append("coverageEndDate", this.coverageEndDate);
      bodyFormData.append("coverageEndMileNumber", this.coverageEndMileNumber);
      bodyFormData.append("lastServiceDate", this.lastServiceDate);
      bodyFormData.append("previousServiceDate", this.previousServiceDate);
      bodyFormData.append("lastInvoiceNumber", this.lastInvoiceNumber);
      bodyFormData.append("previousInvoiceNumber", this.previousInvoiceNumber);
      bodyFormData.append("previousMileNumber", this.previousMileNumber);
      bodyFormData.append("mileNumber", this.mileNumber);
      bodyFormData.append("averageMileNumber", this.averageMileNumber);
      bodyFormData.append("predictMileNumber", this.predictMileNumber);
      bodyFormData.append("branchId", this.form.branchId.branchId);
      bodyFormData.append(
        "plateProvinceId",
        this.form.plateProvinceId.provinceId
      );
      bodyFormData.append("ownerId", this.form.ownerId.cusId);
      bodyFormData.append("driverId", this.form.driverId.cusId);
      bodyFormData.append(
        "typeId",
        this.typeId.typeId != null ? this.typeId.typeId : ""
      );
      if (this.brandId != null) {
        bodyFormData.append(
          "brandId",
          this.brandId.brandId != null ? this.brandId.brandId : ""
        );
      }
      if (this.modelId != null) {
        bodyFormData.append(
          "modelId",
          this.modelId.modelId != null ? this.modelId.modelId : ""
        );
      }
      bodyFormData.append("interiorColorId", this.interiorColorId);
      bodyFormData.append("exteriorColorId", this.exteriorColorId);
      useNetw
        .post("/api/vehicle/store", bodyFormData)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "vehiclelist" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getSelectMasterBrand: function() {
      useNetw
        .get("/api/master/vehicle/brands", {})
        .then((response) => {
          this.masterBrand = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getSelectMasterModel: function(isReset) {
      if(this.brandId != null) {
      useNetw
        .get("/api/master/vehicle/models", {
          params: {
            branchId: this.userBranchId,
            brandId: this.brandId.brandId,
          },
        })
        .then((response) => {
          this.masterModel = response.data.data;
          if (isReset) {
            this.modelId = null;
          }
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          if (isReset) {
            this.brandId = null;
            this.modelId = null;
          }
        })
        .then(() => {});
        }
    },
    getSelectMasterType: function() {
      useNetw
        .get("/api/master/vehicle/types", {})
        .then((response) => {
          this.masterType = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getSelectMasterGear: function() {
      useNetw
        .get("/api/master/vehicle/gear-systems", {})
        .then((response) => {
          this.masterGear = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getSelectMasterProvinces: function() {
      useNetw
        .get("/api/master/address/provinces", {})
        .then((response) => {
          this.provinces = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.form.$touch();
      if (this.$v.form.$invalid != true) {
        this.submitVehicles();
      }
    },
    tooltipFormCustomer() {
      this.submitformcustomer = true;
      this.$v.$touch();
      if (this.$v.validateCustomer.$invalid != true) {
        this.submitUser();
      }
    },

    submitUser: function() {
      const CustomerPost = new FormData();
      CustomerPost.append("branchId", this.userBranch[0]);
      CustomerPost.append("cusType", this.validateCustomer.cusType.type);
      CustomerPost.append(
        "citizenId",
        this.validateCustomer.citizenId != null
          ? this.validateCustomer.citizenId
          : ""
      );
      CustomerPost.append("nameTh", this.validateCustomer.nameCusnew);
      CustomerPost.append(
        "familyNameTh",
        this.validateCustomer.familyNameTh != null
          ? this.validateCustomer.familyNameTh
          : ""
      );
      CustomerPost.append("mobilePhone", this.validateCustomer.mobilePhone);

      useNetw
        .post("api/customer/store", CustomerPost)
        .then((response) => {
          // this.cusIdPost = response.data.cusId;
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.enabled = true;
          this.Hide = true;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs.modalCustomer.hide();
          this.validateCustomer.cusType = null;
          this.validateCustomer.nameCusnew = null;
          this.validateCustomer.mobilePhone = null;
          this.validateCustomer.familyNameTh = null;
          this.validateCustomer.citizenId = null;
        });
    },

    getDataCus: function(name) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/vehicle/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.userBranch,
            nameTh: name,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getDataDrive: function(name) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/vehicle/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.userBranch,
            nameTh: name,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getRemoveSelected: function() {
      this.isLoading = true;
      useNetw
        .get("api/vehicle/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.userBranch,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    removeSelect: function() {
      this.branchId = null;
    },

    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls="addproduct-billinginfo-collapse"
              v-b-toggle.accordion-1
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">ข้อมูลรถ</h5>
                    <!-- <p class="text-muted text-truncate mb-0">
                      Fill all information below
                    </p> -->
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-12">
                    <b-button
                      v-b-modal.modalCustomer
                      class="btn-sm float-end"
                      variant="success"
                      type="submit"
                    >
                      <i class="uil-plus"></i>
                      ลูกค้าใหม่
                    </b-button>
                  </div>
                </div>
                <div class="row align-items-end">
                  <div class="col-8 col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <code> * </code><label for="validationTooltip03">เลขตัวถัง:</label>
                      <input
                        id="validationTooltip03"
                        v-model="form.vin"
                        type="text"
                        class="form-control"
                        :maxlength="20"
                        :class="{
                          'is-invalid': submitform && $v.form.vin.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.vin.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.vin.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip03">หมายเลขตัวถัง:</label>
                      <input
                        id="validationTooltip05"
                        v-model="chassisNumber"
                        type="text"
                        class="form-control"
                        :maxlength="10"
                      />
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip04">เลขเครื่อง:</label>
                      <input
                        v-model="engineNumber"
                        type="text"
                        class="form-control"
                        :maxlength="20"
                      />
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <code> * </code><label for="validationTooltip01">เจ้าของรถ:</label>
                      <multiselect
                        v-model="form.ownerId"
                        label="nameTh"
                        type="search"
                        :options="rowsUser"
                        :show-labels="false"
                        :custom-label="customLabel"
                        open-direction="bottom"
                        @search-change="getDataCus($event)"
                        @remove="getRemoveSelected"
                        placeholder=""
                        class="helo"
                        default
                        :class="{
                          'is-invalid': submitform && $v.form.ownerId.$error,
                        }"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title"
                              >{{ props.option.nameTh }}&nbsp;</span
                            ><span class="option__small">{{
                              props.option.familyNameTh
                            }}</span>
                          </div>
                        </template>
                      </multiselect>
                      <div
                        v-if="submitform && $v.form.ownerId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.ownerId.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <code> * </code><label for="validationTooltip01">ผู้ขับขี่:</label>
                      <multiselect
                        v-model="form.driverId"
                        label="nameTh"
                        type="search"
                        :options="rowsUser"
                        :show-labels="false"
                        :custom-label="customLabel"
                        open-direction="bottom"
                        @search-change="getDataDrive($event)"
                        @remove="getRemoveSelected"
                        placeholder=""
                        class="helo"
                        default
                        :class="{
                          'is-invalid': submitform && $v.form.driverId.$error,
                        }"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title"
                              >{{ props.option.nameTh }}&nbsp;</span
                            ><span class="option__small">{{
                              props.option.familyNameTh
                            }}</span>
                          </div>
                        </template>
                      </multiselect>
                      <div
                        v-if="submitform && $v.form.driverId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.driverId.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 col-sm-4 col-md-1">
                    <div class="mb-3 position-relative">
                      <code> * </code><label for="validationTooltip04">อักษรหน้า:</label>
                      <input
                        v-model="form.platePrefixNumber"
                        type="text"
                        class="form-control"
                        :maxlength="4"
                        :class="{
                          'is-invalid':
                            submitform && $v.form.platePrefixNumber.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.platePrefixNumber.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.platePrefixNumber.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 col-sm-3 col-md-2">
                    <div class="mb-3 position-relative">
                      <code> * </code><label for="validationTooltip02">เลขป้าย:</label>
                      <input
                        id="validationTooltip02"
                        v-model="form.plateNumber"
                        type="text"
                        class="form-control"
                        value="Otto"
                        :maxlength="4"
                        :class="{
                          'is-invalid':
                            submitform && $v.form.plateNumber.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.plateNumber.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.plateNumber.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 col-sm-4 col-md-2">
                    <div class="mb-3 position-relative">
                      <code> * </code><label for="validationTooltip01">จังหวัด:</label>
                      <multiselect
                        v-model="form.plateProvinceId"
                        label="nameTh"
                        :options="provinces"
                        :show-labels="false"
                        class="helo"
                        default
                        placeholder=""
                        :class="{
                          'is-invalid':
                            submitform && $v.form.plateProvinceId.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.form.plateProvinceId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.plateProvinceId.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-5 col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipUsername1"
                        >ป้ายทะเบียน:</label
                      >
                      <div class="input-group">
                        <input
                          v-model="fullLicense"
                          type="text"
                          class="form-control"
                          aria-describedby="validationTooltipUsernamePrepend1"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5 col-sm-4 col-md-4">
                    <div class="mb-3 position-relative">
                      <label>ยี่ห้อ:</label>
                      <div class="input-group">
                        <multiselect
                          v-model="brandId"
                          label="nameEn"
                          placeholder=""
                          :options="masterBrand"
                          :show-labels="false"
                          class="helo"
                          @input="getSelectMasterModel"
                          @remove="removeSelect"
                        >
                        </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-7 col-sm-5 col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01">รุ่น:</label>
                      <multiselect
                        v-model="modelId"
                        label="nameEn"
                        placeholder=""
                        :options="masterModel"
                        :show-labels="false"
                        class="helo"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-3 col-sm-3 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipUsername1">ปีรุ่น:</label>
                      <div class="input-group">
                        <input
                          v-model="modelYear"
                          type="text"
                          class="form-control"
                          aria-describedby="validationTooltipUsernamePrepend1"
                          :maxlength="4"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-2 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01">CC:</label>
                      <input
                        id="validationTooltip01"
                        v-model="cc"
                        type="text"
                        class="form-control"
                        value="Mark"
                      />
                    </div>
                  </div>
                  <div class="col-7 col-sm-5 col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipUsername1">เกียร์:</label>
                      <div class="input-group">
                        <multiselect
                          v-model="gearSystemId"
                          label="nameTh"
                          :options="masterGear"
                          :show-labels="false"
                          class="helo"
                          placeholder=""
                          default
                        >
                        </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 col-sm-5 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01">ประเภทรถ:</label>
                      <multiselect
                        v-model="typeId"
                        label="nameTh"
                        :options="masterType"
                        :show-labels="false"
                        class="helo"
                        placeholder=""
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01">สีภายใน:</label>
                      <input
                        v-model="interiorColorId"
                        type="text"
                        class="form-control"
                        value="Mark"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-md-2">
                    <div class="mb-3 position-relative">
                      <label>สีภายนอก:</label>
                      <div class="input-group">
                        <input
                          v-model="exteriorColorId"
                          type="text"
                          class="form-control"
                          aria-describedby="validationTooltipUsernamePrepend4"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipUsernamePrepend2"
                        >ที่นั่ง:</label
                      >
                      <div class="input-group">
                        <input
                          id="validationTooltipUsername2"
                          v-model="seat"
                          type="text"
                          class="form-control"
                          aria-describedby="validationTooltipUsernamePrepend2"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 col-sm-6 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip02">warrantyBookNo:</label>
                      <input
                        id="validationTooltip08"
                        v-model="warrantyBookNo"
                        type="text"
                        class="form-control"
                        value="Otto"
                        :maxlength="45"
                      />
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipUsernamePrepend3"
                        >keyNo:</label
                      >
                      <div class="input-group">
                        <input
                          id="validationTooltipUsername3"
                          v-model="keyNo"
                          type="text"
                          class="form-control"
                          :maxlength="45"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01">วันที่จัดส่ง:</label>
                      <date-picker
                        v-model="deliveryDate"
                        format="YYYY-MM-DD"
                        value-type="format"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01">วันที่ลงทะเบียน:</label>
                      <date-picker
                        v-model="registrationDate"
                        format="YYYY-MM-DD"
                        value-type="format"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-3">
                    <div class="mb-3 position-relative">
                      <label>วันที่สิ้นสุดความคุ้มครอง:</label>
                      <div class="input-group">
                        <date-picker
                          v-model="coverageEndDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 col-sm-4 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01"
                        >ไมล์สิ้นสุดความคุ้มครอง:</label
                      >
                      <input
                        v-model="coverageEndMileNumber"
                        type="text"
                        class="form-control"
                        value="Mark"
                        :maxlength="20"
                      />
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01"
                        >วันที่ให้บริการล่าสุด:</label
                      >
                      <date-picker
                        v-model="lastServiceDate"
                        format="YYYY-MM-DD"
                        value-type="format"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2">
                    <div class="mb-3 position-relative">
                      <label>วันที่ให้บริการก่อนหน้า:</label>
                      <div class="input-group">
                        <date-picker
                          v-model="previousServiceDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-md-3">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01"
                        >เลขที่ใบแจ้งหนี้ล่าสุด:</label
                      >
                      <input
                        v-model="lastInvoiceNumber"
                        type="text"
                        class="form-control"
                        value="Mark"
                        :maxlength="20"
                      />
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-md-3">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01"
                        >หมายเลขใบแจ้งหนี้ก่อนหน้า:</label
                      >
                      <input
                        v-model="previousInvoiceNumber"
                        type="text"
                        class="form-control"
                        value="Mark"
                        :maxlength="20"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 col-sm-4 col-md-2">
                    <div class="mb-3 position-relative">
                      <label>หมายเลขไมล์ก่อนหน้า:</label>
                      <div class="input-group">
                        <input
                          v-model="previousMileNumber"
                          type="text"
                          class="form-control"
                          aria-describedby="validationTooltipUsernamePrepend4"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01">เลขไมล์:</label>
                      <input
                        v-model="mileNumber"
                        type="text"
                        class="form-control"
                        value="Mark"
                      />
                    </div>
                  </div>
                  <div class="col-6 col-sm-4 col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltip01">จำนวนไมล์เฉลี่ย:</label>
                      <input
                        v-model="averageMileNumber"
                        type="text"
                        class="form-control"
                        value="Mark"
                      />
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-md-2">
                    <div class="mb-3 position-relative">
                      <label>คาดการณ์ไมล์:</label>
                      <div class="input-group">
                        <input
                          v-model="predictMileNumber"
                          type="text"
                          class="form-control"
                          aria-describedby="validationTooltipUsernamePrepend4"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-md-3">
                    <div class="mb-3 position-relative">
                      <code> * </code><label for="validationTooltip01">สาขา:</label>
                      <multiselect
                        v-model="form.branchId"
                        label="nameTh"
                        :options="branchMaster"
                        :show-labels="false"
                        class="helo"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.form.branchId.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.form.branchId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.branchId.required">{{
                          errormessage
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row mb-4">
                  <div class="col text-end ms-1">
                    <!-- <b-button
                      class="btn btn-danger"
                      :to="{ name: 'vehiclelist' }"
                    >
                      <i class="uil uil-times me-1"></i> Cancel
                    </b-button> -->
                    <button
                      href="#"
                      type="submit"
                      class="btn btn-success ms-1"
                      @click="tooltipForm"
                    >
                      <i class="uil uil-file-alt me-1"></i> บันทึก
                    </button>
                  </div>
                  <!-- end col -->
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="modalCustomer"
      id="modalCustomer"
      title="ลูกค้าใหม่"
      hide-footer
      size="lg"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-6 col-sm-6 col-lg-4">
            <div class="mb-3 position-relative">
              <label for="validation">เลขบัตรประจำตัวประชาชน:</label>
              <input
                v-model="validateCustomer.citizenId"
                type="text"
                class="form-control"
                :maxlength="13"
              />
            </div>
          </div>
          <div class="col-6 col-sm-6 col-lg-4">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">ชื่อลูกค้า:</label>

              <input
                v-model="validateCustomer.nameCusnew"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitformcustomer && $v.validateCustomer.nameCusnew.$error,
                }"
              />

              <div
                v-if="
                  submitformcustomer && $v.validateCustomer.nameCusnew.$error
                "
                class="invalid-tooltip"
              >
                <span v-if="!$v.validateCustomer.nameCusnew.required">{{
                  errormessage
                }}</span>
              </div>
            </div>
          </div>

          <div class="col-6 col-sm-6 col-lg-4">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">นามสกุล:</label>
              <input
                v-model="validateCustomer.familyNameTh"
                type="text"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitformcustomer &&
                    $v.validateCustomer.familyNameTh.$error,
                }"
              />
              <div
                v-if="
                  submitformcustomer && $v.validateCustomer.familyNameTh.$error
                "
                class="invalid-tooltip"
              >
                <span v-if="!$v.validateCustomer.familyNameTh.required">{{
                  errormessage
                }}</span>
              </div>
            </div>
          </div>

          <div class="col-6 col-sm-6 col-lg-4">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">ประเภท:</label>
              <multiselect
                v-model="validateCustomer.cusType"
                label="nameTh"
                type="search"
                :options="optioncusType"
                :show-labels="false"
                open-direction="bottom"
                placeholder=""
                :class="{
                  'is-invalid':
                    submitformcustomer && $v.validateCustomer.cusType.$error,
                }"
              >
                <span slot="noResult">ไม่พบข้อมูล</span>
              </multiselect>
              <div
                v-if="submitformcustomer && $v.validateCustomer.cusType.$error"
                class="invalid-tooltip"
              >
                <span v-if="!$v.validateCustomer.cusType.required">{{
                  errormessage
                }}</span>
              </div>
            </div>
          </div>

          <div class="col-6 col-sm-6 col-lg-4">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">เบอร์โทรศัพท์:</label>
              <input
                v-model="validateCustomer.mobilePhone"
                type="text"
                class="form-control"
                :maxlength="10"
                :class="{
                  'is-invalid':
                    submitformcustomer &&
                    $v.validateCustomer.mobilePhone.$error,
                }"
              />
              <div
                v-if="
                  submitformcustomer && $v.validateCustomer.mobilePhone.$error
                "
                class="invalid-tooltip"
              >
                <span
                  v-if="
                    !$v.validateCustomer.mobilePhone.required ||
                      !$v.validateCustomer.mobilePhone.minLength
                  "
                  >{{ errormessage }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="text-end">
            <b-button
              class="btn btn-primary"
              variant="primary"
              @click="tooltipFormCustomer()"
            >
              บันทึก
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
